import publicSecrets from "./publicSecrets.json"

interface Firebase {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

interface Google {
  placesKey: string
  embeddedMapsKey: string
}

interface Emulator {
  host: string
  firestorePort?: number
  authPort?: number
  functionsPort?: number
  storagePort?: number
}

interface Intercom {
  appId: string
}

interface Algolia {
  appId: string
  indexPre: string
}

interface Stripe {
  clientId: string
  key: string
}

interface Settings {
  appUrl: string
  functionsUrl: string
  euFunctionsUrl: string
  version: string
}

interface Integromat {
  environment: string
}

interface Sentry {
  appDsn: string
}

interface Cookiebot {
  id: string
}

interface PostHog {
  key: string
  uiHost: string
  apiHost: string
}

type Env = string

export interface PublicSecrets {
  firebase: Firebase
  stripe: Stripe
  stripeSandbox: Stripe
  algolia: Algolia
  intercom: Intercom
  sentry?: Sentry
  integromat: Integromat
  settings: Settings
  emulator?: Emulator
  google: Google
  cookiebot: Cookiebot
  posthog: PostHog
  env: Env
}

// export const secrets: PublicSecrets = process.env.NEXT_PUBLIC_SECRETS
//   ? require(process.env.NEXT_PUBLIC_SECRETS || "./publicSecrets.json")
//   : publicSecrets
export const secrets: PublicSecrets = publicSecrets
